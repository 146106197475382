// Types and interfaces
interface DatabaseInfo {
  name: string;
  version: number;
}

interface IndexInfo {
  name: string;
  keyPath: string | string[];
  multiEntry: boolean;
  unique: boolean;
}

interface ObjectStoreInfo {
  name: string;
  keyPath: string | string[];
  autoIncrement: boolean;
  indexes: IndexInfo[];
}

interface DatabaseMetadata {
  name: string;
  version: number;
  stores: ObjectStoreInfo[];
  size: number | null;
  quota: number | null;
}

interface StoreStats {
  recordCount: number;
  indexes: string[];
}

interface DatabaseStats {
  totalRecords: number;
  storeStats: Record<string, StoreStats>;
}

interface DatabaseChangeEvent {
  previous: DatabaseStats;
  current: DatabaseStats;
  changes: {
    totalRecordsDelta: number;
    storeChanges: Record<string, { recordsDelta: number }>;
  };
}

interface DatabaseSchema {
  name: string;
  version: number;
  stores: ObjectStoreInfo[];
}

interface ExportedDatabase {
  schema: DatabaseSchema;
  data: Record<string, unknown[]>;
}

type DatabaseChangeCallback = (changes: DatabaseChangeEvent) => void;

class IndexedDBManagerError extends Error {
  constructor(
    message: string,
    public readonly cause?: Error,
  ) {
    console.error(`[IndexedDBManager] Message: ${message}`);
    super(message);
    this.name = 'IndexedDBManagerError';
  }
}

// Main manager class
export class IndexedDBManager {
  private readonly databases: Map<string, IDBDatabase>;

  constructor() {
    this.databases = new Map();
  }

  /**
   * List all IndexedDB databases on the device
   */
  public async listDatabases(): Promise<DatabaseInfo[]> {
    try {
      if (!('databases' in indexedDB)) {
        throw new Error('indexedDB.databases() not supported in this browser');
      }

      const databases = await indexedDB.databases();
      return databases.map((db) => ({
        name: db.name!,
        version: db.version!,
      }));
    } catch (error) {
      throw new IndexedDBManagerError(
        'Failed to list databases',
        error as Error,
      );
    }
  }

  /**
   * Get detailed information about a specific database
   */
  public async inspectDatabase(dbName: string): Promise<DatabaseMetadata> {
    console.log(`[IndexedDBManager] Inspecting database: ${dbName}`);
    return new Promise((resolve, reject) => {
      const request: IDBOpenDBRequest = indexedDB.open(dbName);
      console.log(`[IndexedDBManager] Opened database: ${dbName}`);
      const databaseInfo: DatabaseMetadata = {
        name: dbName,
        version: 0,
        stores: [],
        size: null,
        quota: null,
      };

      request.onblocked = () => {
        console.error(`[IndexedDBManager] Database blocked: ${dbName}`);
        reject(new IndexedDBManagerError(`Database blocked: ${dbName}`));
      };

      request.onupgradeneeded = () => {
        console.log(`[IndexedDBManager] Database upgrade needed: ${dbName}`);
        const db: IDBDatabase = request.result;
        databaseInfo.version = db.version;
        const storeNames = Array.from(db.objectStoreNames);

        if (storeNames.length === 0) {
          console.log(
            `[IndexedDBManager] Database ${dbName} has no object stores`,
          );
          db.close();
          resolve(databaseInfo);
          return;
        }

        // TODO(pbirch): Handle upgrade logic metadata at least

        console.log(
          `[IndexedDBManager] Database upgrade requried for: ${dbName}`,
        );
      };

      request.onerror = () => {
        console.error(
          `[IndexedDBManager] Failed to open database: ${request.error}`,
        );
        reject(
          new IndexedDBManagerError(
            `Failed to open database: ${request.error}`,
          ),
        );
      };

      request.onsuccess = async () => {
        try {
          const db: IDBDatabase = request.result;
          databaseInfo.version = db.version;

          // Get object stores information
          const storeNames = Array.from(db.objectStoreNames);
          console.log(
            `[IndexedDBManager] Open Success: Inspecting database[V${db.version}]: ${dbName}`,
            storeNames,
          );

          // Handle case where database has no stores
          if (storeNames.length === 0) {
            console.log(
              `[IndexedDBManager] Database ${dbName} has no object stores`,
            );
            db.close();
            resolve(databaseInfo);
            return;
          }

          console.log(`[IndexedDBManager] Inspecting stores: ${storeNames}`);
          const transaction = db.transaction(storeNames, 'readonly');

          databaseInfo.stores = storeNames.map((storeName) => {
            console.log(`[IndexedDBManager] Inspecting store: ${storeName}`);
            const store = transaction.objectStore(storeName);
            const indexes = Array.from(store.indexNames).map((indexName) => {
              const index = store.index(indexName);
              return {
                name: indexName,
                keyPath: index.keyPath,
                multiEntry: index.multiEntry,
                unique: index.unique,
              };
            });

            return {
              name: storeName,
              keyPath: store.keyPath,
              autoIncrement: store.autoIncrement,
              indexes,
            };
          });

          // Estimate database size
          console.log(`[IndexedDBManager] Estimating database size: ${dbName}`);
          if ('storage' in navigator && navigator.storage?.estimate) {
            const estimate = await navigator.storage.estimate();
            databaseInfo.size = estimate.usage ?? null;
            databaseInfo.quota = estimate.quota ?? null;
          }

          db.close();
          resolve(databaseInfo);
        } catch (error) {
          console.error(
            `[IndexedDBManager] Failed to inspect database: ${error}`,
          );
          reject(
            new IndexedDBManagerError(
              'Failed to inspect database',
              error as Error,
            ),
          );
        }
      };
    });
  }

  /**
   * Get statistics about a database
   */
  public async getDatabaseStats(
    dbName: string,
    timeoutMs: number = 5000,
  ): Promise<DatabaseStats> {
    return new Promise((resolve, reject) => {
      console.log(`[IndexedDBManager] Getting database stats: ${dbName}`);
      let hasResolved = false;
      const currentVersion = 1;
      const request: IDBOpenDBRequest = indexedDB.open(dbName, currentVersion);

      // Set up timeout
      const timeoutId = setTimeout(() => {
        if (!hasResolved) {
          hasResolved = true;
          console.error(
            `[IndexedDBManager] Operation timed out after ${timeoutMs}ms for database: ${dbName}`,
          );
          try {
            // Attempt to clean up
            if (request && request.result) {
              request.result.close();
            }
          } catch (e) {
            console.warn(
              '[IndexedDBManager] Could not close database connection during timeout',
              e,
            );
          }
          reject(
            new IndexedDBManagerError(
              `Operation timed out after ${timeoutMs}ms`,
            ),
          );
        }
      }, timeoutMs);

      const cleanup = () => {
        clearTimeout(timeoutId);
      };

      request.onblocked = () => {
        console.error(`[IndexedDBManager] Database blocked: ${dbName}`);
        if (!hasResolved) {
          hasResolved = true;
          cleanup();
          reject(new IndexedDBManagerError(`Database blocked: ${dbName}`));
        }
      };

      request.onupgradeneeded = (event) => {
        console.log(
          `[IndexedDBManager] Database upgrade needed: ${dbName} from version ${event.oldVersion} to ${event.newVersion}`,
        );
        const db: IDBDatabase = request.result;

        // Create initial store if needed
        if (!db.objectStoreNames.contains('bands')) {
          console.log(`[IndexedDBManager] Creating initial store 'bands'`);
          const store = db.createObjectStore('bands', { keyPath: 'id' });
          store.createIndex('name', 'name', { unique: false });
          store.createIndex('created', 'created', { unique: false });
        }
      };

      request.onerror = () => {
        console.error(
          `[IndexedDBManager] Failed to get database stats: ${request.error}`,
        );
        if (!hasResolved) {
          hasResolved = true;
          cleanup();
          reject(
            new IndexedDBManagerError(
              `Failed to get database stats: ${request.error}`,
            ),
          );
        }
      };

      request.onsuccess = async () => {
        try {
          console.log(
            `[IndexedDBManager] Successfully opened database: ${dbName}`,
          );
          const db: IDBDatabase = request.result;
          const storeNames = Array.from(db.objectStoreNames);
          const stats: DatabaseStats = {
            totalRecords: 0,
            storeStats: {},
          };

          // Handle case where database has no stores
          if (storeNames.length === 0) {
            console.log(
              `[IndexedDBManager] Database ${dbName} has no object stores`,
            );
            db.close();
            if (!hasResolved) {
              hasResolved = true;
              cleanup();
              resolve(stats);
            }
            return;
          }

          console.log(
            `[IndexedDBManager] Getting stats for stores: ${storeNames}`,
          );
          const transaction = db.transaction(storeNames, 'readonly');

          // Set up transaction error handler
          transaction.onerror = () => {
            console.error(
              `[IndexedDBManager] Transaction error: ${transaction.error}`,
            );
            if (!hasResolved) {
              hasResolved = true;
              cleanup();
              reject(
                new IndexedDBManagerError(
                  `Transaction error: ${transaction.error}`,
                ),
              );
            }
          };

          // Add transaction timeout
          transaction.oncomplete = () => {
            if (!hasResolved) {
              hasResolved = true;
              cleanup();
              db.close();
              resolve(stats);
            }
          };

          // Collect all store statistics with individual timeouts
          await Promise.all(
            storeNames.map(async (storeName) => {
              const store = transaction.objectStore(storeName);
              const countRequest = store.count();

              return new Promise<void>((resolveCount, rejectCount) => {
                const countTimeout = setTimeout(() => {
                  if (!hasResolved) {
                    rejectCount(
                      new Error(
                        `Count operation timed out for store ${storeName}`,
                      ),
                    );
                  }
                }, timeoutMs / 2); // Use shorter timeout for individual operations

                countRequest.onsuccess = () => {
                  clearTimeout(countTimeout);
                  stats.storeStats[storeName] = {
                    recordCount: countRequest.result,
                    indexes: Array.from(store.indexNames),
                  };
                  stats.totalRecords += countRequest.result;
                  resolveCount();
                };

                countRequest.onerror = () => {
                  clearTimeout(countTimeout);
                  rejectCount(
                    new Error(`Failed to count records in store ${storeName}`),
                  );
                };
              });
            }),
          ).catch((error) => {
            if (!hasResolved) {
              hasResolved = true;
              cleanup();
              db.close();
              reject(
                new IndexedDBManagerError(
                  'Failed to get store statistics',
                  error as Error,
                ),
              );
            }
          });
        } catch (error) {
          console.error(
            `[IndexedDBManager] Failed to get database stats: ${error}`,
          );
          if (!hasResolved) {
            hasResolved = true;
            cleanup();
            reject(
              new IndexedDBManagerError(
                'Failed to get database stats',
                error as Error,
              ),
            );
          }
        }
      };
    });
  }

  /**
   * Delete a database
   */
  public async deleteDatabase(dbName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.deleteDatabase(dbName);

      request.onerror = () => {
        reject(
          new IndexedDBManagerError(
            `Failed to delete database: ${request.error}`,
          ),
        );
      };

      request.onsuccess = () => resolve();
    });
  }

  /**
   * Export database schema
   */
  public async exportSchema(dbName: string): Promise<DatabaseSchema> {
    try {
      const dbInfo = await this.inspectDatabase(dbName);
      return {
        name: dbInfo.name,
        version: dbInfo.version,
        stores: dbInfo.stores,
      };
    } catch (error) {
      throw new IndexedDBManagerError(
        'Failed to export schema',
        error as Error,
      );
    }
  }

  /**
   * Export complete database
   */
  public async exportData(dbName: string): Promise<ExportedDatabase> {
    return new Promise((resolve, reject) => {
      const request: IDBOpenDBRequest = indexedDB.open(dbName);

      request.onerror = () => {
        reject(
          new IndexedDBManagerError(`Failed to export data: ${request.error}`),
        );
      };

      request.onsuccess = async () => {
        try {
          const db: IDBDatabase = request.result;
          const storeNames = Array.from(db.objectStoreNames);
          const exportData: ExportedDatabase = {
            schema: await this.exportSchema(dbName),
            data: {},
          };

          const transaction = db.transaction(storeNames, 'readonly');

          await Promise.all(
            storeNames.map(async (storeName) => {
              const store = transaction.objectStore(storeName);
              return new Promise<void>((resolve) => {
                const request = store.getAll();
                request.onsuccess = () => {
                  exportData.data[storeName] = request.result;
                  resolve();
                };
              });
            }),
          );

          db.close();
          resolve(exportData);
        } catch (error) {
          reject(
            new IndexedDBManagerError('Failed to export data', error as Error),
          );
        }
      };
    });
  }

  /**
   * Import data into a database
   */
  public async importData(exportedData: ExportedDatabase): Promise<void> {
    const { schema, data } = exportedData;

    return new Promise((resolve, reject) => {
      const request: IDBOpenDBRequest = indexedDB.open(
        schema.name,
        schema.version,
      );

      request.onerror = () => {
        reject(
          new IndexedDBManagerError(`Failed to import data: ${request.error}`),
        );
      };

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db: IDBDatabase = (event.target as IDBOpenDBRequest).result;

        schema.stores.forEach((storeSchema) => {
          if (!db.objectStoreNames.contains(storeSchema.name)) {
            const store = db.createObjectStore(storeSchema.name, {
              keyPath: storeSchema.keyPath,
              autoIncrement: storeSchema.autoIncrement,
            });

            storeSchema.indexes.forEach((indexSchema) => {
              store.createIndex(indexSchema.name, indexSchema.keyPath, {
                multiEntry: indexSchema.multiEntry,
                unique: indexSchema.unique,
              });
            });
          }
        });
      };

      request.onsuccess = async () => {
        try {
          const db: IDBDatabase = request.result;
          const transaction = db.transaction(Object.keys(data), 'readwrite');

          await Promise.all(
            Object.entries(data).map(([storeName, records]) => {
              const store = transaction.objectStore(storeName);
              return Promise.all(
                records.map((record) => {
                  return new Promise<void>((resolve, reject) => {
                    const request = store.put(record);
                    request.onsuccess = () => resolve();
                    request.onerror = () => reject(request.error);
                  });
                }),
              );
            }),
          );

          db.close();
          resolve();
        } catch (error) {
          reject(
            new IndexedDBManagerError('Failed to import data', error as Error),
          );
        }
      };
    });
  }

  /**
   * Monitor database changes
   */
  public async monitorDatabase(
    dbName: string,
    callback: DatabaseChangeCallback,
  ): Promise<() => void> {
    let previousStats = await this.getDatabaseStats(dbName);

    const monitor = setInterval(async () => {
      try {
        const currentStats = await this.getDatabaseStats(dbName);

        if (JSON.stringify(currentStats) !== JSON.stringify(previousStats)) {
          callback({
            previous: previousStats,
            current: currentStats,
            changes: {
              totalRecordsDelta:
                currentStats.totalRecords - previousStats.totalRecords,
              storeChanges: Object.keys(currentStats.storeStats).reduce(
                (acc, storeName) => {
                  acc[storeName] = {
                    recordsDelta:
                      currentStats.storeStats[storeName]!.recordCount -
                      (previousStats.storeStats[storeName]?.recordCount ?? 0),
                  };
                  return acc;
                },
                {} as Record<string, { recordsDelta: number }>,
              ),
            },
          });
          previousStats = currentStats;
        }
      } catch (error) {
        console.error(
          new IndexedDBManagerError(
            'Error monitoring database',
            error as Error,
          ),
        );
      }
    }, 1000);

    return () => clearInterval(monitor);
  }

  /**
   * Compact database (best effort)
   */
  public async compactDatabase(dbName: string): Promise<void> {
    try {
      const data = await this.exportData(dbName);
      await this.deleteDatabase(dbName);
      await this.importData(data);
    } catch (error) {
      throw new IndexedDBManagerError(
        'Failed to compact database',
        error as Error,
      );
    }
  }
}

// Type guards
export function isDatabaseInfo(value: unknown): value is DatabaseInfo {
  return (
    typeof value === 'object' &&
    value !== null &&
    'name' in value &&
    'version' in value &&
    typeof (value as DatabaseInfo).name === 'string' &&
    typeof (value as DatabaseInfo).version === 'number'
  );
}

export function isExportedDatabase(value: unknown): value is ExportedDatabase {
  return (
    typeof value === 'object' &&
    value !== null &&
    'schema' in value &&
    'data' in value &&
    typeof (value as ExportedDatabase).data === 'object'
  );
}

// Export types
export type {
  DatabaseInfo,
  IndexInfo,
  ObjectStoreInfo,
  DatabaseMetadata,
  StoreStats,
  DatabaseStats,
  DatabaseChangeEvent,
  DatabaseSchema,
  ExportedDatabase,
  DatabaseChangeCallback,
};
