import { Component } from 'solid-js';
import { Button } from '../../components/solidui/button.jsx';
// import { IndexedDBManager } from '~/components/images/utils';
import { IndexedDBManager, ExportedDatabase } from '@repo/database';
// import { ButtonGroup } from '@/components/ui/buttonGroup';

export const DatabaseActions: Component<{
  dbName: string;
  manager: IndexedDBManager;
  onExport: (data: ExportedDatabase) => void;
  onDelete: () => void;
}> = (props) => {
  const handleExport = async () => {
    try {
      const data = await props.manager.exportData(props.dbName);
      props.onExport(data);
    } catch (error) {
      console.error('Export failed:', error);
    }
  };

  const handleDelete = async () => {
    if (
      confirm(`Are you sure you want to delete database "${props.dbName}"?`)
    ) {
      try {
        await props.manager.deleteDatabase(props.dbName);
        props.onDelete();
      } catch (error) {
        console.error('Delete failed:', error);
      }
    }
  };

  return (
    <div>
      <Button onClick={handleExport}>Export Database</Button>
      <Button variant="destructive" onClick={handleDelete}>
        Delete Database
      </Button>
    </div>
  );
};
