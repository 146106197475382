import { Component, createSignal, Show } from 'solid-js';
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from '../../components/solidui/tabs.jsx';
import { IndexedDBManager } from '@repo/database';
import { DatabaseList } from './DatabaseList.jsx';
import { DatabaseActions } from './DatabaseActions.jsx';
import { DatabaseOverview } from './DatabaseOverview.jsx';
import { DatabaseStructure } from './DatabaseStructure.jsx';
import { DatabaseMonitor } from './DatabaseMonitor.jsx';

export type TabId = 'overview' | 'structure' | 'monitor';

export const IndexedDBPage: Component = () => {
  let manager: IndexedDBManager | undefined;
  const [isClient, setIsClient] = createSignal(false);
  const [selectedDb, setSelectedDb] = createSignal<string>();
  const [activeTab, setActiveTab] = createSignal<TabId>('overview');

  // Initialize manager only on client side
  if (typeof window !== 'undefined') {
    manager = new IndexedDBManager();
    setIsClient(true);
  }

  const handleDatabaseSelect = (name: string) => {
    setSelectedDb(name);
    setActiveTab('overview');
  };

  const handleDatabaseDelete = () => {
    setSelectedDb(undefined);
  };

  const handleExport = (data: unknown) => {
    const blob = new Blob([JSON.stringify(data, null, 2)], {
      type: 'application/json',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${selectedDb()}-export.json`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const onDelete = () => {
    console.warn('[IndexedDBPage] onDelete()');
  };

  return (
    // <Show when={isClient()} fallback={<div>Loading...</div>}>
    <div class="container mx-auto p-6">
      <h1 class="text-3xl font-bold mb-8">IndexedDB Manager</h1>

      <div class="grid grid-cols-4 gap-6">
        <div class="col-span-1">
          <Show when={manager}>
            <DatabaseList
              onSelectDatabase={handleDatabaseSelect}
              selectedDatabase={selectedDb()}
            />
          </Show>
        </div>

        <div class="col-span-3">
          <Show
            when={selectedDb() && manager}
            fallback={
              <div class="flex items-center justify-center h-full">
                <p class="text-muted-foreground">
                  Select a database from the list to view details
                </p>
              </div>
            }
          >
            <div class="flex justify-between items-center mb-6">
              <h2 class="text-2xl font-bold">{selectedDb()}</h2>
              <DatabaseActions
                dbName={selectedDb()!}
                manager={manager!}
                onExport={handleExport}
                onDelete={handleDatabaseDelete}
              />
            </div>

            <Tabs
              value={activeTab()}
              onChange={(value) => setActiveTab(value as TabId)}
            >
              <TabsList>
                <TabsTrigger value="overview">Overview</TabsTrigger>
                <TabsTrigger value="structure">Structure</TabsTrigger>
                <TabsTrigger value="monitor">Monitor</TabsTrigger>
              </TabsList>

              <TabsContent value="overview">
                <DatabaseOverview
                  dbName={selectedDb()!}
                  manager={manager!}
                  onDelete={onDelete}
                />
              </TabsContent>

              <TabsContent value="structure">
                <DatabaseStructure dbName={selectedDb()!} manager={manager!} />
              </TabsContent>

              <TabsContent value="monitor">
                <DatabaseMonitor dbName={selectedDb()!} manager={manager!} />
              </TabsContent>
            </Tabs>
          </Show>
        </div>
      </div>
    </div>
    // </Show>
  );
};

export default IndexedDBPage;

// import { Component, createSignal } from 'solid-js';
// import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
// import { IndexedDBManager } from '@repo/database';
// // import { TabId } from '@/components/ui/tabs';
// import { DatabaseList } from '@/features/indexeddb/DatabaseList.jsx';
// import { DatabaseActions } from '@/features/indexeddb/DatabaseActions.jsx';
// import { DatabaseOverview } from '@/features/indexeddb/DatabaseOverview.jsx';
// import { DatabaseStructure } from '@/features/indexeddb/DatabaseStructure.jsx';
// import { DatabaseMonitor } from '@/features/indexeddb/DatabaseMonitor.jsx';

// export const App: Component = () => {
//   const manager = new IndexedDBManager();
//   const [selectedDb, setSelectedDb] = createSignal<string>();
//   const [activeTab, setActiveTab] = createSignal<TabId>('overview');

//   const handleDatabaseSelect = (name: string) => {
//     setSelectedDb(name);
//     setActiveTab('overview');
//   };

//   const handleDatabaseDelete = () => {
//     setSelectedDb(undefined);
//   };

//   return (
//     <div class="container mx-auto p-6">
//       <h1 class="text-3xl font-bold mb-8">IndexedDB Manager</h1>

//       <div class="grid grid-cols-4 gap-6">
//         <div class="col-span-1">
//           <DatabaseList
//             onSelectDatabase={handleDatabaseSelect}
//             selectedDatabase={selectedDb()}
//           />
//         </div>

//         <div class="col-span-3">
//           {selectedDb() ? (
//             <>
//               <div class="flex justify-between items-center mb-6">
//                 <h2 class="text-2xl font-bold">{selectedDb()}</h2>
//                 <DatabaseActions
//                   dbName={selectedDb()!}
//                   manager={manager}
//                   onExport={(data) => {
//                     const blob = new Blob([JSON.stringify(data, null, 2)], {
//                       type: 'application/json',
//                     });
//                     const url = URL.createObjectURL(blob);
//                     const a = document.createElement('a');
//                     a.href = url;
//                     a.download = `${selectedDb()}-export.json`;
//                     a.click();
//                     URL.revokeObjectURL(url);
//                   }}
//                   onDelete={handleDatabaseDelete}
//                 />
//               </div>

//               <Tabs value={activeTab()} onChange={(value) => setActiveTab(value as TabId)}>
//                 <TabsList>
//                   <TabsTrigger value="overview">Overview</TabsTrigger>
//                   <TabsTrigger value="structure">Structure</TabsTrigger>
//                   <TabsTrigger value="monitor">Monitor</TabsTrigger>
//                 </TabsList>

//                 <TabsContent value="overview">
//                   <DatabaseOverview dbName={selectedDb()!} manager={manager} />
//                 </TabsContent>

//                 <TabsContent value="structure">
//                   <DatabaseStructure dbName={selectedDb()!} manager={manager} />
//                 </TabsContent>

//                 <TabsContent value="monitor">
//                   <DatabaseMonitor dbName={selectedDb()!} manager={manager} />
//                 </TabsContent>
//               </Tabs>
//             </>
//           ) : (
//             <div class="flex items-center justify-center h-full">
//               <p class="text-muted-foreground">
//                 Select a database from the list to view details
//               </p>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export type TabId = 'overview' | 'structure' | 'monitor';

// export default App;
