import { Component, For, createSignal, onCleanup } from 'solid-js';
// import { Card, CardHeader, CardContent } from '@/components/ui';
import {
  Card,
  CardHeader,
  CardContent,
} from '../../components/solidui/card.jsx';
// import { DatabaseChangeEvent } from '../lib/indexeddb-manager';
import {
  IndexedDBManager,
  ExportedDatabase,
  DatabaseChangeEvent,
} from '@repo/database';

export const DatabaseMonitor: Component<{
  dbName: string;
  manager: IndexedDBManager;
}> = (props) => {
  const [changes, setChanges] = createSignal<DatabaseChangeEvent[]>([]);
  let stopMonitoring: (() => void) | undefined;

  const startMonitoring = async () => {
    stopMonitoring = await props.manager.monitorDatabase(
      props.dbName,
      (change) => {
        setChanges((prev) => [...prev, change].slice(-50));
      },
    );
  };

  startMonitoring();

  onCleanup(() => {
    if (stopMonitoring) {
      stopMonitoring();
    }
  });

  return (
    <Card>
      <CardHeader>
        <h3 class="text-lg font-medium">Real-time Monitor</h3>
      </CardHeader>
      <CardContent>
        <div class="space-y-2">
          <For each={changes()}>
            {(change) => (
              <div class="border-b py-2">
                <p>Total Records Delta: {change.changes.totalRecordsDelta}</p>
                <div class="ml-4">
                  <For each={Object.entries(change.changes.storeChanges)}>
                    {([store, { recordsDelta }]) => (
                      <p>
                        {store}: {recordsDelta > 0 ? '+' : ''}
                        {recordsDelta} records
                      </p>
                    )}
                  </For>
                </div>
              </div>
            )}
          </For>
        </div>
      </CardContent>
    </Card>
  );
};
