import { Component, createResource, Show } from 'solid-js';
import {
  Card,
  CardHeader,
  CardContent,
} from '../../components/solidui/card.jsx';
import { Alert, AlertDescription } from '../../components/solidui/alert.jsx';
import { Button } from '../../components/solidui/button.jsx';
import { formatBytes } from '@repo/shared';
import { IndexedDBManager } from '@repo/database';

export const DatabaseOverview: Component<{
  dbName: string;
  manager: IndexedDBManager;
  onDelete: () => void;
}> = (props) => {
  const [metadata] = createResource(async () => {
    return await props.manager.inspectDatabase(props.dbName);
  });

  const [stats, { refetch }] = createResource(async () => {
    try {
      return await props.manager.getDatabaseStats(props.dbName, 5000);
    } catch (error) {
      console.error('Failed to get database stats:', error);
      return null;
    }
  });

  const handleDelete = async () => {
    if (
      confirm(
        `Are you sure you want to delete database "${props.dbName}"? This action cannot be undone.`,
      )
    ) {
      try {
        await props.manager.deleteDatabase(props.dbName);
        props.onDelete();
      } catch (error) {
        console.error('Failed to delete database:', error);
      }
    }
  };

  return (
    <div class="space-y-6">
      <Card>
        <CardHeader class="flex items-center justify-between">
          <h3 class="text-lg font-medium">Database Information</h3>
          <Button variant="destructive" size="sm" onClick={handleDelete}>
            <svg
              class="w-4 h-4 mr-2"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            >
              <path d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
            </svg>
            Delete Database
          </Button>
        </CardHeader>
        <CardContent>
          <Show
            when={!metadata.loading}
            fallback={
              <div class="flex items-center justify-center p-4">
                <div class="w-6 h-6 border-t-2 border-primary animate-spin rounded-full" />
              </div>
            }
          >
            <dl class="grid grid-cols-2 gap-4">
              <div>
                <dt class="text-sm font-medium text-muted-foreground">Name</dt>
                <dd class="text-lg">{metadata()?.name}</dd>
              </div>
              <div>
                <dt class="text-sm font-medium text-muted-foreground">
                  Version
                </dt>
                <dd class="text-lg">{metadata()?.version}</dd>
              </div>
              <div>
                <dt class="text-sm font-medium text-muted-foreground">Size</dt>
                <dd class="text-lg">
                  {metadata()?.size
                    ? formatBytes(metadata()?.size ?? 0)
                    : 'Unknown'}
                </dd>
              </div>
              <Show
                when={stats()}
                fallback={
                  <div class="col-span-2">
                    <Alert variant="destructive">
                      <svg
                        class="w-4 h-4"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
                        <line x1="12" y1="9" x2="12" y2="13" />
                        <line x1="12" y1="17" x2="12.01" y2="17" />
                      </svg>
                      <AlertDescription>
                        Failed to load database statistics. The database might
                        be corrupted.
                        <Button
                          variant="outline"
                          size="sm"
                          class="ml-4"
                          onClick={() => refetch()}
                        >
                          Retry
                        </Button>
                        <Button
                          variant="destructive"
                          size="sm"
                          class="ml-2"
                          onClick={handleDelete}
                        >
                          Delete Database
                        </Button>
                      </AlertDescription>
                    </Alert>
                  </div>
                }
              >
                <div>
                  <dt class="text-sm font-medium text-muted-foreground">
                    Total Records
                  </dt>
                  <dd class="text-lg">{stats()?.totalRecords}</dd>
                </div>
              </Show>
            </dl>
          </Show>
        </CardContent>
      </Card>
    </div>
  );
};
