import { Component, createResource, For } from 'solid-js';
import {
  Card,
  CardHeader,
  CardContent,
} from '../../components/solidui/card.jsx';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from '../../components/solidui/table.jsx';
import { IndexedDBManager } from '@repo/database';

export const DatabaseStructure: Component<{
  dbName: string;
  manager: IndexedDBManager;
}> = (props) => {
  const [metadata] = createResource(async () => {
    return await props.manager.inspectDatabase(props.dbName);
  });

  return (
    <div class="space-y-6">
      <For each={metadata()?.stores}>
        {(store) => (
          <Card>
            <CardHeader>
              <h3 class="text-lg font-medium">Store: {store.name}</h3>
              <p class="text-sm text-muted-foreground">
                Key Path: {String(store.keyPath)} | Auto Increment:{' '}
                {String(store.autoIncrement)}
              </p>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableCell>Index Name</TableCell>
                    <TableCell>Key Path</TableCell>
                    <TableCell>Multi Entry</TableCell>
                    <TableCell>Unique</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <For each={store.indexes}>
                    {(index) => (
                      <TableRow>
                        <TableCell>{index.name}</TableCell>
                        <TableCell>{String(index.keyPath)}</TableCell>
                        <TableCell>{String(index.multiEntry)}</TableCell>
                        <TableCell>{String(index.unique)}</TableCell>
                      </TableRow>
                    )}
                  </For>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        )}
      </For>
    </div>
  );
};
