import { Component, createSignal, createResource, For, Show } from 'solid-js';
import { Button } from '../../components/solidui/button.jsx';
import { Card } from '../../components/solidui/card.jsx';
import { Badge } from '../../components/solidui/badge.jsx';
import { Alert, AlertDescription } from '../../components/solidui/alert.jsx';
// import { Card, Button, Badge } from '@/components/ui';
// import { IndexedDBManager } from '../lib/indexeddb-manager';
import { IndexedDBManager, ExportedDatabase } from '@repo/database';

export const DatabaseList: Component<{
  onSelectDatabase: (name: string) => void;
  selectedDatabase?: string;
}> = (props) => {
  const manager = new IndexedDBManager();

  const [databases, { refetch }] = createResource(async () => {
    try {
      return await manager.listDatabases();
    } catch (error) {
      console.error('Failed to load databases:', error);
      return null;
    }
  });

  return (
    <div class="space-y-4">
      <div class="flex justify-between items-center">
        <h2 class="text-2xl font-bold">Databases</h2>
        <Button variant="outline" size="sm" onClick={() => refetch()}>
          Refresh
        </Button>
      </div>

      <Show
        when={databases() !== null}
        fallback={
          <Alert variant="destructive">
            <svg
              class="w-4 h-4"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            >
              <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
              <line x1="12" y1="9" x2="12" y2="13" />
              <line x1="12" y1="17" x2="12.01" y2="17" />
            </svg>
            <AlertDescription>
              Failed to load databases. Please refresh the page.
            </AlertDescription>
          </Alert>
        }
      >
        <div class="grid gap-4">
          <For each={databases()}>
            {(db) => (
              <Card
                class={`cursor-pointer transition-colors ${
                  props.selectedDatabase === db.name ? 'bg-primary/10' : ''
                }`}
                onClick={() => props.onSelectDatabase(db.name)}
              >
                <div class="flex items-center justify-between p-4">
                  <div>
                    <h3 class="text-lg font-medium">{db.name}</h3>
                    <p class="text-sm text-muted-foreground">
                      Version: {db.version}
                    </p>
                  </div>
                  <Badge>
                    {props.selectedDatabase === db.name
                      ? 'Selected'
                      : 'Click to view'}
                  </Badge>
                </div>
              </Card>
            )}
          </For>
        </div>
      </Show>
    </div>
  );
};
